var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

(function (global, factory) {
  factory(exports);
})(exports, function (exports) {
  'use strict'; // AST walker module for Mozilla Parser API compatible trees
  // A simple walk is one where you simply specify callbacks to be
  // called on specific nodes. The last two arguments are optional. A
  // simple use would be
  //
  //     walk.simple(myTree, {
  //         Expression: function(node) { ... }
  //     });
  //
  // to do something with all expressions. All Parser API node types
  // can be used to identify node types, as well as Expression and
  // Statement, which denote categories of nodes.
  //
  // The base argument can be used to pass a custom (recursive)
  // walker, and state can be used to give this walked an initial
  // state.

  function simple(node, visitors, baseVisitor, state, override) {
    if (!baseVisitor) {
      baseVisitor = base;
    }

    (function c(node, st, override) {
      var type = override || node.type,
          found = visitors[type];
      baseVisitor[type](node, st, c);

      if (found) {
        found(node, st);
      }
    })(node, state, override);
  } // An ancestor walk keeps an array of ancestor nodes (including the
  // current node) and passes them to the callback as third parameter
  // (and also as state parameter when no other state is present).


  function ancestor(node, visitors, baseVisitor, state, override) {
    var ancestors = [];

    if (!baseVisitor) {
      baseVisitor = base;
    }

    (function c(node, st, override) {
      var type = override || node.type,
          found = visitors[type];
      var isNew = node !== ancestors[ancestors.length - 1];

      if (isNew) {
        ancestors.push(node);
      }

      baseVisitor[type](node, st, c);

      if (found) {
        found(node, st || ancestors, ancestors);
      }

      if (isNew) {
        ancestors.pop();
      }
    })(node, state, override);
  } // A recursive walk is one where your functions override the default
  // walkers. They can modify and replace the state parameter that's
  // threaded through the walk, and can opt how and whether to walk
  // their child nodes (by calling their third argument on these
  // nodes).


  function recursive(node, state, funcs, baseVisitor, override) {
    var visitor = funcs ? make(funcs, baseVisitor || undefined) : baseVisitor;

    (function c(node, st, override) {
      visitor[override || node.type](node, st, c);
    })(node, state, override);
  }

  function makeTest(test) {
    if (typeof test === "string") {
      return function (type) {
        return type === test;
      };
    } else if (!test) {
      return function () {
        return true;
      };
    } else {
      return test;
    }
  }

  var Found = function Found(node, state) {
    (this || _global).node = node;
    (this || _global).state = state;
  }; // A full walk triggers the callback on each node


  function full(node, callback, baseVisitor, state, override) {
    if (!baseVisitor) {
      baseVisitor = base;
    }

    (function c(node, st, override) {
      var type = override || node.type;
      baseVisitor[type](node, st, c);

      if (!override) {
        callback(node, st, type);
      }
    })(node, state, override);
  } // An fullAncestor walk is like an ancestor walk, but triggers
  // the callback on each node


  function fullAncestor(node, callback, baseVisitor, state) {
    if (!baseVisitor) {
      baseVisitor = base;
    }

    var ancestors = [];

    (function c(node, st, override) {
      var type = override || node.type;
      var isNew = node !== ancestors[ancestors.length - 1];

      if (isNew) {
        ancestors.push(node);
      }

      baseVisitor[type](node, st, c);

      if (!override) {
        callback(node, st || ancestors, ancestors, type);
      }

      if (isNew) {
        ancestors.pop();
      }
    })(node, state);
  } // Find a node with a given start, end, and type (all are optional,
  // null can be used as wildcard). Returns a {node, state} object, or
  // undefined when it doesn't find a matching node.


  function findNodeAt(node, start, end, test, baseVisitor, state) {
    if (!baseVisitor) {
      baseVisitor = base;
    }

    test = makeTest(test);

    try {
      (function c(node, st, override) {
        var type = override || node.type;

        if ((start == null || node.start <= start) && (end == null || node.end >= end)) {
          baseVisitor[type](node, st, c);
        }

        if ((start == null || node.start === start) && (end == null || node.end === end) && test(type, node)) {
          throw new Found(node, st);
        }
      })(node, state);
    } catch (e) {
      if (e instanceof Found) {
        return e;
      }

      throw e;
    }
  } // Find the innermost node of a given type that contains the given
  // position. Interface similar to findNodeAt.


  function findNodeAround(node, pos, test, baseVisitor, state) {
    test = makeTest(test);

    if (!baseVisitor) {
      baseVisitor = base;
    }

    try {
      (function c(node, st, override) {
        var type = override || node.type;

        if (node.start > pos || node.end < pos) {
          return;
        }

        baseVisitor[type](node, st, c);

        if (test(type, node)) {
          throw new Found(node, st);
        }
      })(node, state);
    } catch (e) {
      if (e instanceof Found) {
        return e;
      }

      throw e;
    }
  } // Find the outermost matching node after a given position.


  function findNodeAfter(node, pos, test, baseVisitor, state) {
    test = makeTest(test);

    if (!baseVisitor) {
      baseVisitor = base;
    }

    try {
      (function c(node, st, override) {
        if (node.end < pos) {
          return;
        }

        var type = override || node.type;

        if (node.start >= pos && test(type, node)) {
          throw new Found(node, st);
        }

        baseVisitor[type](node, st, c);
      })(node, state);
    } catch (e) {
      if (e instanceof Found) {
        return e;
      }

      throw e;
    }
  } // Find the outermost matching node before a given position.


  function findNodeBefore(node, pos, test, baseVisitor, state) {
    test = makeTest(test);

    if (!baseVisitor) {
      baseVisitor = base;
    }

    var max;

    (function c(node, st, override) {
      if (node.start > pos) {
        return;
      }

      var type = override || node.type;

      if (node.end <= pos && (!max || max.node.end < node.end) && test(type, node)) {
        max = new Found(node, st);
      }

      baseVisitor[type](node, st, c);
    })(node, state);

    return max;
  } // Fallback to an Object.create polyfill for older environments.


  var create = Object.create || function (proto) {
    function Ctor() {}

    Ctor.prototype = proto;
    return new Ctor();
  }; // Used to create a custom walker. Will fill in all missing node
  // type properties with the defaults.


  function make(funcs, baseVisitor) {
    var visitor = create(baseVisitor || base);

    for (var type in funcs) {
      visitor[type] = funcs[type];
    }

    return visitor;
  }

  function skipThrough(node, st, c) {
    c(node, st);
  }

  function ignore(_node, _st, _c) {} // Node walkers.


  var base = {};

  base.Program = base.BlockStatement = function (node, st, c) {
    for (var i = 0, list = node.body; i < list.length; i += 1) {
      var stmt = list[i];
      c(stmt, st, "Statement");
    }
  };

  base.Statement = skipThrough;
  base.EmptyStatement = ignore;

  base.ExpressionStatement = base.ParenthesizedExpression = base.ChainExpression = function (node, st, c) {
    return c(node.expression, st, "Expression");
  };

  base.IfStatement = function (node, st, c) {
    c(node.test, st, "Expression");
    c(node.consequent, st, "Statement");

    if (node.alternate) {
      c(node.alternate, st, "Statement");
    }
  };

  base.LabeledStatement = function (node, st, c) {
    return c(node.body, st, "Statement");
  };

  base.BreakStatement = base.ContinueStatement = ignore;

  base.WithStatement = function (node, st, c) {
    c(node.object, st, "Expression");
    c(node.body, st, "Statement");
  };

  base.SwitchStatement = function (node, st, c) {
    c(node.discriminant, st, "Expression");

    for (var i$1 = 0, list$1 = node.cases; i$1 < list$1.length; i$1 += 1) {
      var cs = list$1[i$1];

      if (cs.test) {
        c(cs.test, st, "Expression");
      }

      for (var i = 0, list = cs.consequent; i < list.length; i += 1) {
        var cons = list[i];
        c(cons, st, "Statement");
      }
    }
  };

  base.SwitchCase = function (node, st, c) {
    if (node.test) {
      c(node.test, st, "Expression");
    }

    for (var i = 0, list = node.consequent; i < list.length; i += 1) {
      var cons = list[i];
      c(cons, st, "Statement");
    }
  };

  base.ReturnStatement = base.YieldExpression = base.AwaitExpression = function (node, st, c) {
    if (node.argument) {
      c(node.argument, st, "Expression");
    }
  };

  base.ThrowStatement = base.SpreadElement = function (node, st, c) {
    return c(node.argument, st, "Expression");
  };

  base.TryStatement = function (node, st, c) {
    c(node.block, st, "Statement");

    if (node.handler) {
      c(node.handler, st);
    }

    if (node.finalizer) {
      c(node.finalizer, st, "Statement");
    }
  };

  base.CatchClause = function (node, st, c) {
    if (node.param) {
      c(node.param, st, "Pattern");
    }

    c(node.body, st, "Statement");
  };

  base.WhileStatement = base.DoWhileStatement = function (node, st, c) {
    c(node.test, st, "Expression");
    c(node.body, st, "Statement");
  };

  base.ForStatement = function (node, st, c) {
    if (node.init) {
      c(node.init, st, "ForInit");
    }

    if (node.test) {
      c(node.test, st, "Expression");
    }

    if (node.update) {
      c(node.update, st, "Expression");
    }

    c(node.body, st, "Statement");
  };

  base.ForInStatement = base.ForOfStatement = function (node, st, c) {
    c(node.left, st, "ForInit");
    c(node.right, st, "Expression");
    c(node.body, st, "Statement");
  };

  base.ForInit = function (node, st, c) {
    if (node.type === "VariableDeclaration") {
      c(node, st);
    } else {
      c(node, st, "Expression");
    }
  };

  base.DebuggerStatement = ignore;

  base.FunctionDeclaration = function (node, st, c) {
    return c(node, st, "Function");
  };

  base.VariableDeclaration = function (node, st, c) {
    for (var i = 0, list = node.declarations; i < list.length; i += 1) {
      var decl = list[i];
      c(decl, st);
    }
  };

  base.VariableDeclarator = function (node, st, c) {
    c(node.id, st, "Pattern");

    if (node.init) {
      c(node.init, st, "Expression");
    }
  };

  base.Function = function (node, st, c) {
    if (node.id) {
      c(node.id, st, "Pattern");
    }

    for (var i = 0, list = node.params; i < list.length; i += 1) {
      var param = list[i];
      c(param, st, "Pattern");
    }

    c(node.body, st, node.expression ? "Expression" : "Statement");
  };

  base.Pattern = function (node, st, c) {
    if (node.type === "Identifier") {
      c(node, st, "VariablePattern");
    } else if (node.type === "MemberExpression") {
      c(node, st, "MemberPattern");
    } else {
      c(node, st);
    }
  };

  base.VariablePattern = ignore;
  base.MemberPattern = skipThrough;

  base.RestElement = function (node, st, c) {
    return c(node.argument, st, "Pattern");
  };

  base.ArrayPattern = function (node, st, c) {
    for (var i = 0, list = node.elements; i < list.length; i += 1) {
      var elt = list[i];

      if (elt) {
        c(elt, st, "Pattern");
      }
    }
  };

  base.ObjectPattern = function (node, st, c) {
    for (var i = 0, list = node.properties; i < list.length; i += 1) {
      var prop = list[i];

      if (prop.type === "Property") {
        if (prop.computed) {
          c(prop.key, st, "Expression");
        }

        c(prop.value, st, "Pattern");
      } else if (prop.type === "RestElement") {
        c(prop.argument, st, "Pattern");
      }
    }
  };

  base.Expression = skipThrough;
  base.ThisExpression = base.Super = base.MetaProperty = ignore;

  base.ArrayExpression = function (node, st, c) {
    for (var i = 0, list = node.elements; i < list.length; i += 1) {
      var elt = list[i];

      if (elt) {
        c(elt, st, "Expression");
      }
    }
  };

  base.ObjectExpression = function (node, st, c) {
    for (var i = 0, list = node.properties; i < list.length; i += 1) {
      var prop = list[i];
      c(prop, st);
    }
  };

  base.FunctionExpression = base.ArrowFunctionExpression = base.FunctionDeclaration;

  base.SequenceExpression = function (node, st, c) {
    for (var i = 0, list = node.expressions; i < list.length; i += 1) {
      var expr = list[i];
      c(expr, st, "Expression");
    }
  };

  base.TemplateLiteral = function (node, st, c) {
    for (var i = 0, list = node.quasis; i < list.length; i += 1) {
      var quasi = list[i];
      c(quasi, st);
    }

    for (var i$1 = 0, list$1 = node.expressions; i$1 < list$1.length; i$1 += 1) {
      var expr = list$1[i$1];
      c(expr, st, "Expression");
    }
  };

  base.TemplateElement = ignore;

  base.UnaryExpression = base.UpdateExpression = function (node, st, c) {
    c(node.argument, st, "Expression");
  };

  base.BinaryExpression = base.LogicalExpression = function (node, st, c) {
    c(node.left, st, "Expression");
    c(node.right, st, "Expression");
  };

  base.AssignmentExpression = base.AssignmentPattern = function (node, st, c) {
    c(node.left, st, "Pattern");
    c(node.right, st, "Expression");
  };

  base.ConditionalExpression = function (node, st, c) {
    c(node.test, st, "Expression");
    c(node.consequent, st, "Expression");
    c(node.alternate, st, "Expression");
  };

  base.NewExpression = base.CallExpression = function (node, st, c) {
    c(node.callee, st, "Expression");

    if (node.arguments) {
      for (var i = 0, list = node.arguments; i < list.length; i += 1) {
        var arg = list[i];
        c(arg, st, "Expression");
      }
    }
  };

  base.MemberExpression = function (node, st, c) {
    c(node.object, st, "Expression");

    if (node.computed) {
      c(node.property, st, "Expression");
    }
  };

  base.ExportNamedDeclaration = base.ExportDefaultDeclaration = function (node, st, c) {
    if (node.declaration) {
      c(node.declaration, st, node.type === "ExportNamedDeclaration" || node.declaration.id ? "Statement" : "Expression");
    }

    if (node.source) {
      c(node.source, st, "Expression");
    }
  };

  base.ExportAllDeclaration = function (node, st, c) {
    if (node.exported) {
      c(node.exported, st);
    }

    c(node.source, st, "Expression");
  };

  base.ImportDeclaration = function (node, st, c) {
    for (var i = 0, list = node.specifiers; i < list.length; i += 1) {
      var spec = list[i];
      c(spec, st);
    }

    c(node.source, st, "Expression");
  };

  base.ImportExpression = function (node, st, c) {
    c(node.source, st, "Expression");
  };

  base.ImportSpecifier = base.ImportDefaultSpecifier = base.ImportNamespaceSpecifier = base.Identifier = base.Literal = ignore;

  base.TaggedTemplateExpression = function (node, st, c) {
    c(node.tag, st, "Expression");
    c(node.quasi, st, "Expression");
  };

  base.ClassDeclaration = base.ClassExpression = function (node, st, c) {
    return c(node, st, "Class");
  };

  base.Class = function (node, st, c) {
    if (node.id) {
      c(node.id, st, "Pattern");
    }

    if (node.superClass) {
      c(node.superClass, st, "Expression");
    }

    c(node.body, st);
  };

  base.ClassBody = function (node, st, c) {
    for (var i = 0, list = node.body; i < list.length; i += 1) {
      var elt = list[i];
      c(elt, st);
    }
  };

  base.MethodDefinition = base.Property = function (node, st, c) {
    if (node.computed) {
      c(node.key, st, "Expression");
    }

    c(node.value, st, "Expression");
  };

  exports.ancestor = ancestor;
  exports.base = base;
  exports.findNodeAfter = findNodeAfter;
  exports.findNodeAround = findNodeAround;
  exports.findNodeAt = findNodeAt;
  exports.findNodeBefore = findNodeBefore;
  exports.full = full;
  exports.fullAncestor = fullAncestor;
  exports.make = make;
  exports.recursive = recursive;
  exports.simple = simple;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
});

export default exports;
export const ancestor = exports.ancestor,
      base = exports.base,
      findNodeAfter = exports.findNodeAfter,
      findNodeAround = exports.findNodeAround,
      findNodeAt = exports.findNodeAt,
      findNodeBefore = exports.findNodeBefore,
      full = exports.full,
      fullAncestor = exports.fullAncestor,
      make = exports.make,
      recursive = exports.recursive,
      simple = exports.simple,
      __esModule = exports.__esModule;